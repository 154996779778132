import { graphql, Link } from "gatsby"
import React from "react"
import Layout from "../components/layout-offline-application"
import Seo from "../components/seo"

//components

import { StaticImage } from "gatsby-plugin-image"
import { LazyLoadComponent } from "react-lazy-load-image-component"
import "react-lazy-load-image-component/src/effects/blur.css"
import loadable from "@loadable/component"
import pMinDelay from "p-min-delay"
import Hero from "../components/hero/hero-retail-management"

import bethany from "../images/bethany.jpg"
import craig from "../images/craig.jpg"
import drew from "../images/drew.jpeg"
import jane from "../images/jane.jpg"
import liz from "../images/liz.jpg"

const Cases = loadable(() => pMinDelay(import("../components/cases"), 500))
const Modal = loadable(() => import("../components/modal/modal-retail"))
// const ExitIntentModal = loadable(() =>
//   import("../components/modal/exit-intent-offline-application")
// )
const UnlockEnterprise = loadable(() =>
  pMinDelay(import("../components/unlock-enterprise"), 500)
)
const Testimonials2 = loadable(() =>
  pMinDelay(import("../components/swiper/testimonial-swiper"), 500)
)

class RetailManagementPage extends React.Component {
  render() {
    const siteTitle = this.props.data.site.siteMetadata.title

    return (
      <Layout
        location={this.props.location}
        title={siteTitle}
        banner={<Hero />}
      >
        <Seo
          title="Retail | Quandary Consulting Group"
          description="Want to unify commerce? Improve data orchestration? Automate fulfillment processes? We build custom low-code applications that do just that.
          "
        />
        <div id="main">
          {/* <ExitIntentModal /> */}
          <LazyLoadComponent>
            {/* <section
              className="quickbase-support"
              style={{ padding: "5rem 0" }}
            >
              <div className="container">
                <div className="row">
                  <div className="col-lg-8 mx-auto text-center">
                    <h1>Construction Management Apps That Work. Anywhere.</h1>
                    <p>
                      Manage complex construction workflows from any mobile
                      device. (No internet required.) Updates automatically when
                      reconnected to your systems.
                    </p>
                    <div className="relative">
                      <Modal />
                    </div>
                  </div>
                </div>
              </div>
            </section> */}
            <section
              className="quickbase-integrations"
              style={{ padding: "5rem 0" }}
            >
              <div className="container">
                <div className="row">
                  <div className="col-lg-12 text-center">
                    <h2 className="h3 text-white mt-4">
                      CUSTOM RETAIL MANAGEMENT APPLICATIONS FOR EVERY PROJECT
                    </h2>
                    <p className="text-white">
                      Keep up with customer demands. Reduce operational costs.
                      And increase profitability.
                    </p>
                  </div>

                  <div className="col-lg-4 col-md-6 mt-5 text-center">
                    <StaticImage
                      placeholder="blurred"
                      className="img-fluid rounded"
                      src="../images/proj-mng.png"
                      alt="Workato Logo"
                      layout="constrained"
                      style={{ width: "25%" }}
                      loading="lazy"
                    />
                    <p className="my-4 text-white font-weight-bold text-uppercase">
                      Composable Architecture
                    </p>
                    <p className="pt-2 text-white">
                      Integrate the newest solutions and features in your
                      existing digital infrastructure to deliver a personalized
                      customer experience.
                    </p>
                  </div>
                  <div className="col-lg-4 col-md-6 mt-5 text-center">
                    <StaticImage
                      placeholder="blurred"
                      className="img-fluid rounded"
                      src="../images/realtime-data.png"
                      alt="Workato Logo"
                      layout="constrained"
                      style={{ width: "25%" }}
                      loading="lazy"
                    />
                    <p className="my-4 text-white font-weight-bold text-uppercase">
                      Real-Time Data
                    </p>
                    <p className="pt-2 text-white">
                      Gain real-time insights into market trends, operations,
                      and customer demands from a single, accessible dashboard.
                    </p>
                  </div>
                  <div className="col-lg-4 col-md-6 mt-5 text-center">
                    <StaticImage
                      placeholder="blurred"
                      className="img-fluid rounded"
                      src="../images/mobile-list.png"
                      alt="Workato Logo"
                      layout="constrained"
                      style={{ width: "25%" }}
                      loading="lazy"
                    />
                    <p className="my-4 text-white font-weight-bold text-uppercase">
                      Logistics Management
                    </p>
                    <p className="pt-2 text-white">
                      Manage inventory and track across multiple locations.
                      Streamline logistics monitoring. Audit sites with ease.
                    </p>
                  </div>
                  <div className="col-lg-4 col-md-6 mt-5 text-center">
                    <StaticImage
                      placeholder="blurred"
                      className="img-fluid rounded"
                      src="../images/nextgen-oms.png"
                      alt="Workato Logo"
                      layout="constrained"
                      style={{ width: "25%" }}
                      loading="lazy"
                    />
                    <p className="my-4 text-white font-weight-bold text-uppercase">
                      Next-Gen OMS
                    </p>
                    <p className="pt-2 text-white">
                      Streamline customer orders and delivery while reducing
                      errors and waste from overstocking or underselling.
                    </p>
                  </div>
                  <div className="col-lg-4 col-md-6 mt-5 text-center">
                    <StaticImage
                      placeholder="blurred"
                      className="img-fluid rounded"
                      src="../images/erp-functionality.png"
                      alt="Workato Logo"
                      layout="constrained"
                      style={{ width: "25%" }}
                      loading="lazy"
                    />
                    <p className="my-4 text-white font-weight-bold text-uppercase">
                      Extend ERP Functionality
                    </p>
                    <p className="pt-2 text-white">
                      Build additional functionality into your existing business
                      infrastructure to improve operations and the customer
                      experience.
                    </p>
                  </div>
                  <div className="col-lg-4 col-md-6 mt-5 text-center">
                    <StaticImage
                      placeholder="blurred"
                      className="img-fluid rounded"
                      src="../images/unified-commerce.png"
                      alt="Workato Logo"
                      layout="constrained"
                      style={{ width: "25%" }}
                      loading="lazy"
                    />
                    <p className="my-4 text-white font-weight-bold text-uppercase">
                      Unified Commerce
                    </p>
                    <p className="pt-2 text-white">
                      Deliver a truly omnichannel buying experience across both
                      physical and digital channels that create a
                      hyper-personalized customer journey.
                    </p>
                  </div>
                </div>
              </div>
            </section>
            {/* <section
              className="quickbase-support"
              style={{ padding: "5rem 0" }}
            >
              <div className="container">
                <div className="row">
                  <div className="col-lg-8 mx-auto text-center">
                    <h1>IMPROVE YOUR TEAM’S EFFICIENCY</h1>
                    <p>
                      These are a few examples of the types of workflows we can
                      manage offline using custom applications. Reach out with
                      your specific challenge. We’ll show you what kind of
                      solution we can build for you.
                    </p>
                    <div className="relative">
                      <Modal />
                    </div>
                  </div>
                </div>
              </div>
            </section> */}
            {/* <section className="py-3">
              <div className="container">
                <div className="row">
                  <div className="col-lg-8 mx-auto text-center pt-5">
                    <i>
                      "The Quandary Team came to the table with an immense
                      amount of knowledge regarding a project management
                      application of QB. Their comprehensive Launch and Support
                      have been invaluable to our team."
                    </i>
                  </div>
                  <div className="col-lg-4 mx-auto text-center pt-2">
                    <img className="avatar" src={bethany} alt="bethany" />
                    <br />
                    <b>Bethany Hart</b>
                    <br />
                    <b>Digital Transformation Strategist</b>
                  </div>
                </div>
              </div>
            </section> */}
            <section className="what-to-expect" style={{ padding: "5rem 0" }}>
              <div className="container pb-5">
                <div className="row">
                  <div className="col-lg-12 col-md-8 mx-auto text-center">
                    <h2 className="font-weight-medium">WE KNOW RETAIL.</h2>
                    <p className="mt-3">
                      We work with you to ensure your custom applications meet
                      the needs of your team. Across all your storefronts.
                    </p>
                  </div>
                </div>
                <div className="row" style={{ padding: "65px 0px 0px 0px" }}>
                  <div
                    className="col mb-4 mx-5 what-to-expext-box text-center"
                    style={{ borderRadius: "5px" }}
                  >
                    <p className="text-green pt-3 font-weight-bold">
                      DISCOVERY
                    </p>
                    <p className="my-3 pb-3">
                      We understand your business first. Digging deep to uncover
                      the source of the problem.
                    </p>
                  </div>
                  <div
                    className="col mb-4 mx-5 what-to-expext-box text-center"
                    style={{ borderRadius: "5px" }}
                  >
                    <p className="text-green pt-3 font-weight-bold">
                      DEVELOPMENT
                    </p>
                    <p className="my-3 pb-3">
                      We build high-quality, custom applications quickly using
                      low-code tools in weeks (not 12-18 months).
                    </p>
                  </div>
                  <div
                    className="col mb-4 mx-5 what-to-expext-box text-center"
                    style={{ borderRadius: "5px" }}
                  >
                    <p className="text-green pt-3 font-weight-bold">
                      DEPLOYMENT
                    </p>
                    <p className="my-3 pb-3">
                      We launch the new tools and support your team while
                      sharing opportunities we uncovered for future growth.
                    </p>
                  </div>
                </div>
                <div className="col-lg-8 mx-auto text-center pt-5">
                  <Modal />
                </div>
              </div>
            </section>
            {/* </LazyLoad> */}
            {/* <LazyLoad height={200} once> */}
            {/* <section
              id="it-consulting"
              className="it-consulting"
              style={{ backgroundColor: "whitesmoke" }}
            >
              <div className="container-fluid px-5">
                <div className="row">
                  <div className="col text-center mt-5 pb-5 px-5 mx-3">
                    <div className="pb-3">
                      <span className="fa fa-star checked px-2"></span>
                      <span className="fa fa-star checked px-2"></span>
                      <span className="fa fa-star checked px-2"></span>
                      <span className="fa fa-star checked px-2"></span>
                      <span className="fa fa-star checked px-2"></span>
                    </div>
                    <h3 className="text-green">Quick Base Experts</h3>
                    <p className="my-4" style={{ minHeight: "5rem" }}>
                      Alex joined our team as a consultant to help us
                      specifically with one project that we needed a Quick Base
                      expert on. He has not only provided value on that specific
                      project but has also quickly become one of the most
                      popular names mentioned as someone to reach out to
                      whenever someone needs help with the system. He is
                      responsive, thorough, and one of the best assets to our
                      team as we work to stabilize and build the application to
                      scale.
                    </p>
                    <div className="py-3">
                      <img className="avatar" src={liz} alt="liz" />
                      <p>
                        Liz Mazur
                        <br />
                        Applications and Technology Solutionist
                        <br />
                        Walgreens
                      </p>
                    </div>
                  </div>

                  <div className="col text-center mt-5 pb-5 px-5 mx-3">
                    <div className="pb-3">
                      <span className="fa fa-star checked px-2"></span>
                      <span className="fa fa-star checked px-2"></span>
                      <span className="fa fa-star checked px-2"></span>
                      <span className="fa fa-star checked px-2"></span>
                      <span className="fa fa-star checked px-2"></span>
                    </div>
                    <h3 className="text-green">A Fantastic Partner</h3>
                    <p className="my-4" style={{ minHeight: "5rem" }}>
                      I have been working with Quandary Consulting Group and
                      Quick Base for almost 2 years. Quickbase provided a way to
                      streamline a unique billing/payable process. Quandary
                      designed a program specifically to transfer information
                      between multiple systems. Tracking and reconciling
                      information is efficient and simple. The staff reacts
                      quickly to the changing processes by providing timely
                      updates and superior support.
                    </p>
                    <div className="py-3">
                      <img className="avatar" src={jane} alt="jane" />
                      <p>
                        Jane Baum
                        <br />
                        Accounting Manager
                        <br />
                        Jacobs
                      </p>
                    </div>
                  </div>
                  <div className="col text-center mt-5 pb-5 px-5 mx-3">
                    <div className="pb-3">
                      <span className="fa fa-star checked px-2"></span>
                      <span className="fa fa-star checked px-2"></span>
                      <span className="fa fa-star checked px-2"></span>
                      <span className="fa fa-star checked px-2"></span>
                      <span className="fa fa-star checked px-2"></span>
                    </div>
                    <h3 className="text-green">Collaborative and Supportive</h3>
                    <p className="my-4" style={{ minHeight: "5rem" }}>
                      The Quandary team was the ideal business partner. They
                      took the initiative to learn and understand our business
                      to provide solutions that would best benefit our
                      organization and key users. Christine Garam and Melissa
                      Doran level of customer services was absolutely World
                      Class; always making themselves available when a question
                      arose.
                    </p>
                    <div className="py-3">
                      <img className="avatar" src={craig} alt="craig" />
                      <p>
                        Craig Baptista
                        <br />
                        Vice President
                        <br />
                        Bureau Veritas NA
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </section> */}
            <section id="case-studies-container">
              <div id="case-studies" className="bg-indigo">
                <div className="container-fluid">
                  <div className="intro text-center">
                    <div className="row">
                      <div className="col-md-10 mx-auto">
                        <p
                          className="h5 text-light-blue text-uppercase font-weight-bold m-0"
                          style={{ fontFamily: "Futura,sans-serif" }}
                        >
                          Case Studies
                        </p>
                        <h2 className=" my-2 text-white">
                          We Save Clients 1000s of Hours. Every Year.
                        </h2>
                        {/* <p className="text-white">
                          We helped a national healthcare provider save over
                          9,000 hours in weekly labor costs by developing a
                          single application...
                        </p> */}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="">
                  <Cases
                    allSanityCaseStudy={this.props.data.allSanityCaseStudy}
                  />
                </div>
                <div className="text-center">
                  <Link
                    className="btn btn-primary btn-arrow mr-2 mt-4 text-center"
                    to="/case-studies/"
                  >
                    More Case Studies
                  </Link>
                </div>
              </div>
            </section>
            <section id="case-studies-container">
              <div id="case-studies">
                <div className="container">
                  <div className="row">
                    <div className="col-lg-8 mx-auto text-center">
                      <h2>Simplify Retail Management</h2>
                      <p>
                        Your customers want to feel seen. Give them a truly
                        memorable shopping experience. Without burning through
                        your IT budget.
                      </p>

                      <div className="relative">
                        <Modal />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
            <Testimonials2 />
            <UnlockEnterprise />
            {/* <section>
              <div className="container py-3">
                <div className="row">
                  <div className="col-lg-8 mx-auto text-center pt-5">
                    <i>
                      "This is a very clean and effective way to show the date
                      from the LL Program. It shows all cost saves and project
                      count in an easy and useful way to show the client. The
                      graphics also create an aesthetically appealing way to
                      examine the data even further. Great work, the STR team
                      could benefit from creating a ProTrack capture like this
                      as well."
                    </i>
                  </div>
                  <div className="col-lg-4 mx-auto text-center pt-2">
                    <img className="avatar-offline" src={drew} alt="drew" />
                    <br />
                    <b>Drew Simon</b> <br />
                    <p>
                      Project Manager <br />
                      JLL
                    </p>
                  </div>
                </div>
              </div>
            </section> */}
          </LazyLoadComponent>
        </div>
      </Layout>
    )
  }
}

export default RetailManagementPage

export const RetailManagementPageQuery = graphql`
  query RetailManagementPageQuery {
    site {
      siteMetadata {
        title
        author
        description
      }
    }
    allSanityCaseStudy(sort: { fields: [publishedAt], order: DESC }, limit: 3) {
      edges {
        node {
          title
          publishedAt(formatString: "MMMM DD, YYYY")
          mainImage {
            asset {
              gatsbyImageData(
                fit: FILL
                placeholder: BLURRED
                sizes: ""
                layout: CONSTRAINED
              )
            }
          }
          bannerImage {
            asset {
              gatsbyImageData(
                fit: FILL
                placeholder: BLURRED
                sizes: ""
                layout: CONSTRAINED
              )
            }
          }
          slug {
            current
          }
        }
      }
    }
  }
`
